<template>
  <div class="testimonials">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <h2>
            {{ $t('homePage.testimonials.trust') }}
          </h2>
          <!-- <p>
            {{ $t("weAreHappyMessage") }}
          </p> -->
        </div>
        <div class="col-lg-7">
          <Swiper
            :slides-per-view="1"
            slidesPerView="1"
            :slidesPerView="'auto'"
            :pagination="{ clickable: true }"
            :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
            :modules="modules"
            :navigation="{
              nextEl: '.testimonials-swiper-button-next',
              prevEl: '.testimonials-swiper-button-prev',
            }"
            spaceBetween="12"
            :breakpoints="{
              '576': {
                slidesPerView: 2,
              },
              '1200': {
                slidesPerView: 2,
              },
            }"
          >
            <swiper-slide>
              <HomeTestimonialsCard :name="$t('commentName1')" :content="$t('comment1')" />
            </swiper-slide>
            <swiper-slide>
              <HomeTestimonialsCard :name="$t('commentName2')" :content="$t('comment2')" />
            </swiper-slide>
            <swiper-slide>
              <HomeTestimonialsCard :name="$t('commentName3')" :content="$t('comment3')" />
            </swiper-slide>
            <swiper-slide>
              <HomeTestimonialsCard :name="$t('commentName4')" :content="$t('comment4')" />
            </swiper-slide>

            <div class="testimonials-swiper-button-next" id="testimonials-swiper-button-next">
              <img src="@/assets/img/arrow_right.svg" v-if="locale == 'en'" />
              <img src="@/assets/img/arrow_left.svg" v-else />
            </div>

            <div class="testimonials-swiper-button-prev" id="testimonials-swiper-button-prev">
              <img src="@/assets/img/arrow_left.svg" v-if="locale == 'en'" />
              <img src="@/assets/img/arrow_right.svg" v-else />
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { locale } = useI18n();
const rtl = computed(() => {
  return locale == 'ar';

  // return $i18n.locale == "ar";
});
const modules = ref([SwiperNavigation, SwiperPagination, SwiperAutoplay]);
</script>

<style lang="scss" scoped>
.testimonials-swiper-button-prev,
.testimonials-swiper-button-next {
  position: absolute;
  cursor: pointer;
  justify-content: center;
  z-index: 999;
}

.testimonials {
  // min-height: 620px;
  background-color: $color-primary;
  padding: 62px 0;

  h2 {
    font-size: 64px;
    line-height: 94px;
    color: #fff;

    @extend.min-title-font;
    margin-bottom: 28px;
  }

  p {
    color: #fff;
    margin: 0;
  }

  :deep(.swiper-pagination) {
    transform: translateY(-42px);
  }

  :deep(.swiper) {
    padding-bottom: 50px;

    .testimonials-swiper-button-prev,
    .testimonials-swiper-button-next {
      display: flex;
      align-items: center;

      &:after {
        display: none;
      }

      background-color: #0373ff;
      height: 40px;
      width: 40px;

      top: unset;
      bottom: 0;
    }

    .testimonials-swiper-button-next {
      right: var(--swiper-navigation-sides-offset, 10px) !important;
      left: auto !important;

      @include dir(rtl) {
        left: var(--swiper-navigation-sides-offset, 10px) !important;
        right: auto !important;
      }

      img {
        @include dir(rtl) {
          transform: translateX(15px);
        }

        @include dir(ltr) {
          transform: translateX(-15px);
        }
      }
    }

    .testimonials-swiper-button-prev {
      left: var(--swiper-navigation-sides-offset, 10px) !important;
      right: auto !important;

      @include dir(rtl) {
        right: var(--swiper-navigation-sides-offset, 10px) !important;
        left: auto !important;
      }

      img {
        @include dir(rtl) {
          transform: translateX(-15px);
        }

        @include dir(ltr) {
          transform: translateX(15px);
        }
      }
    }

    .swiper-button-disabled {
      display: none;
    }

    .swiper-pagination {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 7px;

      @include dir(ltr) {
        padding-right: 70px;
      }

      @include dir(rtl) {
        padding-left: 70px;
      }

      .swiper-pagination-bullet {
        background-color: #f3634799;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background-color: $color-secondary-light;
        }
      }
    }
  }

  @include media(772) {
    padding: 28px 10px;
    min-height: auto;

    h2 {
      font-size: 40px;
      margin-bottom: 16px;
      line-height: 1.2;
    }

    p {
      margin-bottom: 28px;
    }

    :deep(.swiper) {
      padding-bottom: 70px;
    }

    :deep(.swiper-pagination) {
      transform: translateY(-42px);
    }
  }

  @include media(992) {
    padding: 28px 10px;

    :deep(.swiper-pagination) {
      transform: translateY(-42px);
    }

    p {
      margin-bottom: 28px;
    }
  }
}
</style>
