<template>
  <div class="newTitle" :class="{ 'text-start': right }">
    <h2 class="title">{{ title }}</h2>
    <p class="prag pt-4" v-if="prag">
      {{ prag }}
      <b v-if="pragBold">{{ pragBold }}</b>
    </p>
  </div>
</template>

<script>
export default {
  props: ['title', 'prag', 'pragBold', 'right'],
};
</script>

<style lang="scss" scoped>
.newTitle {
  text-align: center;
  padding-bottom: 60px;
  @include media(772) {
    padding-bottom: 16px;
  }
  .title {
    color: $color-primary;
    @extend.min-title-font;
    font-size: 64px;
    font-weight: 700;
    @include media(992) {
      font-size: 44px;
    }
    @include media(772) {
      font-size: 40px;
    }
  }
}
</style>
