<template>
  <div class="container">
    <div class="referral-section">
      <h2 class="title">
        {{ $t('homePage.referral.title') }}
      </h2>
      <p class="content">
        {{ $t('homePage.referral.subtitle') }}
      </p>
      <LocLink :to="redirectLink">
        <Button>
          {{ $t('homePage.referral.actionBtn') }}
        </Button>
      </LocLink>
      <div class="row mt-5">
        <div v-for="(card, index) in cards" :key="index" class="col-12 col-lg-4 cards">
          <Card class="d-flex h-100 cards">
            <template #content>
              <img :src="`./assets/svg/${card.image}`" alt="referral" />
              <h5 class="my-4 card-title">{{ card.title }}</h5>
              <p>{{ card.content }}</p>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n();
const token = useCookie('token');
let redirectLink = ref(null);
redirectLink = token.value ? '/profile/referral' : '/auth/login';
const cards = ref([
  {
    title: t('homePage.referral.cards.cardOne.title'),
    content: t('homePage.referral.cards.cardOne.content'),
    image: 'cursor.svg',
  },
  {
    title: t('homePage.referral.cards.cardTwo.title'),
    content: t('homePage.referral.cards.cardTwo.content'),
    image: 'user-plus.svg',
  },
  {
    title: t('homePage.referral.cards.cardThree.title'),
    content: t('homePage.referral.cards.cardThree.content'),
    image: 'hand.svg',
  },
]);
</script>

<style lang="scss">
.referral-section {
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  background: rgb(31, 41, 156);
  background: linear-gradient(0deg, rgba(31, 41, 156, 0.5) 0%, rgba(255, 255, 255, 1) 100%);
  @media (max-width: 1025px) {
    padding: 20px;
  }
  .title {
    font-size: 64px;
    color: #1f299c;
    margin-bottom: 20px;
    @media (max-width: 1025px) {
      font-size: 40px;
    }
  }
  .content {
    margin-bottom: 20px;
  }
  .cards {
    @media (max-width: 1025px) {
      .p-card {
        display: block;
        height: auto !important;
        margin-bottom: 20px;
      }
    }
    .card-title {
      color: #1f299c;
    }
  }
}
</style>
