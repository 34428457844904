<template>
  <div class="quick-apply-button" v-show="isVisible">
    <img src="~/assets/img/QuickApplyButton.svg" v-ripple class="img-fluid" />
    <span class="text">{{ $t('Quickapply') }}</span>
    <LocLink to="/quickapply" class="stretched-link" @click="saveGA"></LocLink>
  </div>
</template>

<script setup>
const isVisible = ref(false);

const checkPosition = () => {
  try {
    const windowHeight = window.innerHeight;
    const fullPageHeight = document.body.offsetHeight;
    const scrolledFromTop = window.scrollY;
    const scrollableDistance = fullPageHeight - windowHeight;
    const threshold = 250; // Distance from the bottom where the div will hide

    isVisible.value = scrollableDistance - scrolledFromTop > threshold;
  } catch (error) {
    isVisible.value = true;
  }
};

onMounted(() => {
  window.addEventListener('scroll', checkPosition);
  checkPosition(); // Initial check in case the page is already near the bottom
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkPosition);
});
</script>

<script>
import { getAnalytics, logEvent } from 'firebase/analytics';
export default {
  methods: {
    saveGA() {
      const analytics = getAnalytics();
      logEvent(analytics, 'click_quick_apply', { name: 'click_quick_apply' });
    },
  },
};
</script>
<style lang="scss" scoped>
.quick-apply-button {
  position: fixed;
  z-index: 99900;
  bottom: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 20px;
  background-color: $color-secondary;
  box-shadow: 0px 4px 4px 0px #f3634740;
  border-radius: 8px;
  @include media(772) {
    padding: 5px 10px;
    gap: 6;
    img {
      height: 30px;
    }
  }
  @include hover(0.2) {
    background-color: $color-secondary-light;
  }
  @include dir(rtl) {
    left: 90px;
    @include media(772) {
      & {
        left: 20px;
      }
    }
  }
  @include dir(ltr) {
    right: 90px;
    @include media(772) {
      & {
        right: 20px;
      }
    }
  }
  span {
    color: #fff;
    font-size: 20px;
    @extend .min-title-font;
    font-weight: 600;
    @include media(772) {
      font-size: 14px;
    }
  }
}
</style>
