<template>
  <div class="acceptix-numbers">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="section-title text-center text-lg-start">
            <p>
              {{ $t('homePage.acceptixNumbers.title') }}
            </p>
            <h5 class="">
              {{ $t('Acceptix') }}
            </h5>
          </div>
        </div>
        <div class="col-12 col-lg-8 mt-5 mt-lg-0">
          <div class="statistics-cards-container">
            <GeneralStatisticsCard
              icon="/assets/svg/trophy.svg"
              :value="`+10 ${$t('years')}`"
              :title="$t('homePage.statistics.ofSuccess')"
            />
            <GeneralStatisticsCard
              icon="/assets/svg/trophy.svg"
              value="+100.000"
              :title="$t('homePage.statistics.studentsCounselled')"
            />
            <GeneralStatisticsCard
              icon="/assets/svg/trophy.svg"
              value="+700"
              :title="$t('homePage.statistics.universityPartners')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
.acceptix-numbers {
  background-color: #ebedff;
  padding: 50px 0;
  text-align: center;
  .section-title {
    text-align: start;
    p {
      color: #000000;
      font-size: 32px;
      line-height: 56px;
      font-weight: 600;
    }
    h5 {
      color: #1f299c;
      font-size: 40px;
      font-weight: 700;
    }
  }
  .statistics-cards-container {
    display: flex;
    gap: 20px;
    align-items: stretch;
    justify-content: stretch;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
}
</style>
