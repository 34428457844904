<template>
  <div class="top-certified">
    <div class="title-box text-center py-4 mb-4">
      <h2 class="title pb-4">{{ $t('acceptixCertified') }}</h2>
      <p class="prag">
        {{ $t('acceptixCertifiedPrag') }}
      </p>
    </div>

    <div class="container content mt-4 pt-4">
      <div class="swiper-container">
        <div class="swiper-button-next">
          <i v-if="$i18n.locale == 'en'" class="pi pi-chevron-right" style="color: #bdbdbd"></i>
          <i v-else class="pi pi-chevron-left" style="color: #bdbdbd"></i>
        </div>
        <div class="swiper-button-prev">
          <i v-if="$i18n.locale == 'en'" class="pi pi-chevron-left" style="color: #bdbdbd"></i>
          <i v-else class="pi pi-chevron-right" style="color: #bdbdbd"></i>
        </div>
        <Swiper
          ref="swiperRef"
          :slidesPerView="'auto'"
          :initialSlide="0"
          :pagination="{ clickable: true }"
          :modules="modules"
          :dir="rtl"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }"
          spaceBetween="50"
          :breakpoints="{
            '442': {
              slidesPerView: 2,
            },
            '640': {
              slidesPerView: 2,
            },
            '768': {
              slidesPerView: 3,
            },
            '1024': {
              slidesPerView: 4,
            },
          }"
        >
          <swiper-slide class="text-center">
            <img src="/png/BC Registration center 1.png" class="img-fluid" />
          </swiper-slide>
          <swiper-slide class="text-center">
            <img src="/png/BC Approved_1 1.png" class="img-fluid" />
          </swiper-slide>

          <!-- <swiper-slide class="text-center">
            <img src="/png/Screenshot 2024-04-24 at 11.21 1.png" class="img-fluid" />
          </swiper-slide> -->
          <swiper-slide class="text-center">
            <img src="/png/ICEF_badge 1.png" class="img-fluid" />
          </swiper-slide>
          <swiper-slide class="text-center">
            <img src="/png/nafsa-global.png" class="img-fluid" />
          </swiper-slide>

          <!-- <div class="swiper-button-next">
          <i
            class="pi pi-chevron-right"
            style="color: #bdbdbd"
            v-if="locale == 'en'"
          ></i>
          <i class="pi pi-chevron-left" style="color: #bdbdbd" v-else></i>
        </div>

        <div class="swiper-button-prev">
          <i
            class="pi pi-chevron-left"
            style="color: #bdbdbd"
            v-if="locale == 'en'"
          ></i>
          <i class="pi pi-chevron-right" style="color: #bdbdbd" v-else></i>
        </div> -->
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAxios } from '@/composables/useAxios';

const { locale } = useI18n();
const modules = ref([SwiperNavigation, SwiperPagination, SwiperAutoplay]);

const swiperRef = ref(null);
</script>

<script>
export default {
  async mounted() {
    this.loading = true;
    const { api } = useAxios();
    api.get(`/universities?ranking=1&per_page=10`).then(res => {
      this.universitiesLogo = res.data.data;
      this.loading = false;
    });
  },
  data() {
    return {
      universitiesLogo: [],
      loading: false,
    };
  },
  computed: {
    rtl() {
      return this.$i18n.locale == 'ar';
    },
  },
};
</script>
<style scoped lang="scss">
.title-box {
  background-color: #ebedff;

  .title {
    font-size: 64px;
    font-weight: 500;
    color: $color-primary;

    @include media(772) {
      font-size: 42px;
    }
  }

  .prag {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    width: 24%;
    margin: 0 auto;
    color: #000;

    @include media(772) {
      width: 100%;
    }
  }
}

.top-certified {
  // background-image: url("@/assets/img/top_universities.jpg");
  // // min-height: 620px;
  // position: relative;
  // background-repeat: no-repeat;
  // background-size: cover;
  padding: 62px 0 88px;
  color: #fff;

  :deep(.swiper-slide) {
    width: auto;
  }

  h2 {
    font-size: 64px;
    text-align: center;

    @extend.min-title-font;
  }

  .content {
    position: relative;
    z-index: 2;
  }

  .swiper-container {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
      top: 50%;
      display: none;

      &:after {
        display: none;
      }

      height: 37px;
      width: 37px;
      background-color: #fff;
      border-radius: 50%;

      .pi {
        font-size: 22px;
      }
    }

    .swiper-button-prev {
      left: -50px;

      @include dir(rtl) {
        right: -50px;
        left: unset;
      }
    }

    .swiper-button-next {
      right: -50px;

      @include dir(rtl) {
        left: -50px;
        right: unset;
      }
    }
  }

  :deep(.swiper) {
    margin-top: 30px;
    padding-bottom: 40px;
    // padding-right: 45px;
    // padding-left: 45px;

    .swiper-pagination-bullet {
      background-color: #fff !important;
      opacity: 0.6;

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }

    .swiper-pagination {
      transform: translateY(12px);
    }

    .swiper-slide {
      img {
        max-width: 100%;
        height: 91px;

        object-fit: contain;
      }
    }
  }

  @include media(772) {
    h2 {
      font-size: 40px;
    }

    :deep(.swiper) {
      padding-right: 15px;
      padding-left: 15px;
    }

    @include media(772) {
      background-position: center top;

      h2 {
        font-size: 40px;
      }

      :deep(.swiper) {
        padding-right: 15px;
        padding-left: 15px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }

    @include media(992) {
      background-position: center top;

      :deep(.swiper) {
        padding-right: 13px;
        padding-left: 13px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
  }
}
</style>
