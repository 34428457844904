<template>
  <div class="howWorkSection">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="howWork-box web-content" :class="visible ? 'removeContent' : ''" @click="playVideo">
            <div class="play-button" v-if="!visible">
              <svg width="104" height="103" viewBox="0 0 104 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M51.9998 94.4167C28.0798 94.4167 8.6665 75.19 8.6665 51.5C8.6665 27.81 28.0798 8.58337 51.9998 8.58337C75.9198 8.58337 95.3332 27.81 95.3332 51.5C95.3332 75.19 75.9198 94.4167 51.9998 94.4167ZM68.2524 52.3028C68.7907 51.903 68.7907 51.0971 68.2524 50.6973L44.9295 33.3732C44.2696 32.8831 43.3332 33.3541 43.3332 34.176V68.8241C43.3332 69.646 44.2696 70.1169 44.9295 69.6268L68.2524 52.3028Z"
                  fill="#C2D1D9"
                />
                <mask
                  id="mask0_22_513"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="8"
                  y="8"
                  width="88"
                  height="87"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M51.9998 94.4167C28.0798 94.4167 8.6665 75.19 8.6665 51.5C8.6665 27.81 28.0798 8.58337 51.9998 8.58337C75.9198 8.58337 95.3332 27.81 95.3332 51.5C95.3332 75.19 75.9198 94.4167 51.9998 94.4167ZM68.2524 52.3028C68.7907 51.903 68.7907 51.0971 68.2524 50.6973L44.9295 33.3732C44.2696 32.8831 43.3332 33.3541 43.3332 34.176V68.8241C43.3332 69.646 44.2696 70.1169 44.9295 69.6268L68.2524 52.3028Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_22_513)">
                  <rect width="104" height="103" fill="white" fill-opacity="0.5" />
                </g>
              </svg>
            </div>
            <div class="content" v-if="!visible">
              <h3 class="title">{{ $t('home.Howitworks') }}</h3>
              <p class="prag white-prag">
                {{ $t('home.HowitworksPrag') }}
              </p>
            </div>
            <video
              ref="video1"
              poster="@/assets/img/howWork.webp"
              style="border-radius: 10px"
              :controls="visible"
              class="vediaBox"
              :src="videoSrc"
            ></video>
          </div>
        </div>
      </div>
    </div>
    <div class="howWork-box mobile-content" :class="visible ? 'removeContent' : ''" @click="playVideo('video2')">
      <div class="play-button" v-if="!visible">
        <svg width="104" height="103" viewBox="0 0 104 103" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M51.9998 94.4167C28.0798 94.4167 8.6665 75.19 8.6665 51.5C8.6665 27.81 28.0798 8.58337 51.9998 8.58337C75.9198 8.58337 95.3332 27.81 95.3332 51.5C95.3332 75.19 75.9198 94.4167 51.9998 94.4167ZM68.2524 52.3028C68.7907 51.903 68.7907 51.0971 68.2524 50.6973L44.9295 33.3732C44.2696 32.8831 43.3332 33.3541 43.3332 34.176V68.8241C43.3332 69.646 44.2696 70.1169 44.9295 69.6268L68.2524 52.3028Z"
            fill="#C2D1D9"
          />
          <mask
            id="mask0_22_513"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="8"
            y="8"
            width="88"
            height="87"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M51.9998 94.4167C28.0798 94.4167 8.6665 75.19 8.6665 51.5C8.6665 27.81 28.0798 8.58337 51.9998 8.58337C75.9198 8.58337 95.3332 27.81 95.3332 51.5C95.3332 75.19 75.9198 94.4167 51.9998 94.4167ZM68.2524 52.3028C68.7907 51.903 68.7907 51.0971 68.2524 50.6973L44.9295 33.3732C44.2696 32.8831 43.3332 33.3541 43.3332 34.176V68.8241C43.3332 69.646 44.2696 70.1169 44.9295 69.6268L68.2524 52.3028Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_22_513)">
            <rect width="104" height="103" fill="white" fill-opacity="0.5" />
          </g>
        </svg>
      </div>
      <div class="content" v-if="!visible">
        <h3 class="title">{{ $t('home.Howitworks') }}</h3>
        <p class="prag white-prag">
          {{ $t('home.HowitworksPrag') }}
        </p>
      </div>
      <video
        ref="video2"
        poster="@/assets/img/howWork.webp"
        style="border-radius: 10px"
        :controls="visible"
        class="vediaBox"
        :src="videoSrc"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      videoSrc: 'https://sc-photo-upload.s3.eu-central-1.amazonaws.com/videos/acceptix.mp4',
    };
  },
  methods: {
    playVideo(video) {
      if (this.visible) return; // stop function if overlay is not showing

      this.visible = true;
      if (video === 'video2') {
        this.$refs.video2.play();
      } else {
        this.$refs.video1.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.howWorkSection {
  // padding: 110px 0;
  .howWork-box {
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    height: fit-content;
    background-position: center;
    background-size: cover;
    border-radius: 6px;
    display: flex;
    align-items: flex-end;

    &.mobile-content {
      display: none;
    }

    @include media(772) {
      &.web-content {
        display: none;
      }

      &.mobile-content {
        display: flex;
      }
    }

    &.removeContent {
      &::after {
        display: none;
      }
    }

    @include after {
      background: rgba($color-black, 0.46);
      inset: 0;
      z-index: 1;
    }
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .content {
    position: absolute;
    z-index: 2;
    padding: 40px;

    @include media(672) {
      padding: 12px;
    }

    .title {
      color: rgba($color-white, 1);
      font-size: 48px;
      font-weight: 700;
      padding-bottom: 28px;
    }

    .prag {
      max-width: 427px;
    }
  }

  .vediaBox {
    width: 100%;
    height: auto;
  }
}

.iframBoxContainer {
  .iframBox {
    width: 100%;
    height: 600px;
  }
}

@include media(992) {
  .howWorkSection {
    .content {
      .title {
        font-size: 42px;
      }
    }
  }
}

@include media(768) {
  .howWorkSection {
    .content {
      .title {
        font-size: 23px;
      }

      .prag {
        font-size: 13px;
      }
    }
  }
}

@include media(672) {
  .howWorkSection {
    .play-button {
      svg {
        width: 33px;
        height: 34px;
      }

      .content {
        .title {
          font-size: 22px;
          padding-bottom: 15px;
        }

        .prag {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
